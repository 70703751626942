<template>
  <div>
    <div class="container h-100 bg-first p-1">
      <b-row>
        <b-col md="12">
          <article class="mt-1 lottery-search pb-2 pt-2">
            <!-- <img src="/icon/ss.svg" alt="" height="100"> -->
            <div class="text-center">
              <h3><i class="fas fa-list-ul" /> รายการสลากเล่มที่ {{ $route.params.id }}</h3>
            </div>
            <div class="container-sm">
              <b-row class="mt-2">
                <b-col cols="6">
                  <b-button
                    block
                    size="lg"
                    variant="outline-primary"
                    @click="addcard($route.params.id)"
                  >
                    <i class="fas fa-shopping-basket" /> เพิ่มลงตะกร้า</b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                    block
                    size="lg"
                    variant="primary"
                    class="btn-add"
                    @click="agentBuy()"
                  ><i class="fas fa-cart-plus" /> เลือกสลากเพิ่ม</b-button>
                </b-col>
              </b-row>
            </div>
          </article>
        </b-col>
      </b-row>

      <div>
        <div class="mt-2">
          <h3 class="text-white text-center">
            --รายการสลาก--
          </h3>
        </div>

        <b-row>
          <b-col
            v-for="item in lotto"
            :key="item._id"
            md="6"
            lg="4"
            cols="12"
            class="mt-2"
          >
            <div class="lotto-items">
              <div class="card7">
                <div class="chip">
                  <span class="bankk">{{ item.lottonumber }}</span>
                </div>
                <div class="number">
                  {{ item.roundth }}
                </div>
                <!-- <div class="name">
                  <div class="price-lotto">
                    <h1>{{ item.price }} บาท</h1>
                  </div>
                </div> -->
                <div class="from">
                  <span class="txt-a">งวดที่</span> 1
                </div>
                <div class="to">
                  <span class="txt-a">ชุดที่</span> {{ item.series_no }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <div class="mt-1">
          <b-pagination
            v-model="currentPage"
            class="mt-1"
            hide-goto-end-buttons
            :total-rows="totalRows"
            first-number
            last-number
            align="center"
            @input="getLotterybyset($route.params.id)"
          />
          <br><br><br>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'IndexPage',
  components: {
    BRow, BCol, BButton, BPagination,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      lottoset: null,
      lottobyset: [],
      lotto: null,
      totalRows: 100,
      currentPage: 1,
    }
  },
  mounted() {
    this.getLotterybyset(this.$route.params.id)
  },
  methods: {
    addcard() {
      if (localStorage.getItem('userData')) {
        const Obj = {
          lotto: this.$route.params.id,
        }
        this.$http
          .post('https://uatapi.sabaideelotto.com/api/lottery/addcartset', Obj)
          .then(() => {
            this.$router.push('/agent/buy-lottery')
          })
          .catch(error => console.log(error))
      } else {
        this.SwalError('กรุณา เข้าสู่ระบบก่อนทำการซื้อ')
      }
    },
    agentBuy() {
      this.$router.push('/agent/buy-lottery')
    },
    getLotterybyset(item) {
      const Obj = {
        set_no: item,
        page: this.currentPage,
      }
      this.$http
        .post('https://uatapi.sabaideelotto.com/api/lottery/getlotterybyset', Obj)
        .then(response => {
          this.lotto = response.data
        })
        .catch(error => console.log(error))
    },
  },
}
</script>

<style scoped></style>
